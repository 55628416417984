/*jshint globalstrict: true*/
/* jshint node: true */
"use strict";

class SettingsManager {

    constructor() {
        console.log('Powering up Settings Manager');
        this.libraryLink = '';

        this.rateSpeed = null;
        this.interWordDelay = null;
        this.useGlobalSpeed = false;

        this.hoverState = true;
        this.DEFAULT_HOVER_STATE = true;

        this.wsButtonState = true;
        this.wsGlobalButtonState = true;
        this.wsPageButtonState = null;

        this.autoApplyLastReplacementWord = false;
        this.DEFAULT_AUTO_APPLY_LAST_REPLACEMENT_WORD = false;
        this.hasRanAutoApplyLastReplacementWord = false;

        this.wordForReplacement = null;
        this.replacementWordHistory = new Set();

        this.notes = '';
        this.isNotesShown = false;
        this.isFeedbackShown = true;
        this.isStewardOptionsShown = false;

        this.contentNotes = '';
        this.isContentNotesShown = false;

        this.DEFAULT_SELECTED_VOICE = 'male_level_2';   // Man default
        this.selectedVoice = this.DEFAULT_SELECTED_VOICE;
        this.pageSelectedVoice = null;

        this.settingsModal = null;

        this.replaceWordsHistory = new Set();
        this.isDocPreparedForReplacement = false;

        this.isWideLayout = false;

        this.pageId = null;
        this.pageName = '';

        this.initialize();
    }

    setPageName(name) {
        this.pageName = name;
    }

    setShowWsButton(value) {
        console.log('WS BUTTON: Setting param ' + value);

        if (!LocalStorageWrapper.get('wsButtonState')) {
            let wsButtonSwitch = document.getElementById('ws_button_switch');
            wsButtonSwitch.checked = value;
            this.wsButtonState = value;
        }

        this.wsPageButtonState = value;
    }

    setLibraryLink(link) {
        this.libraryLink = link;
        this.processLibraryLink();
    }

    setPageId(id) {
        this.pageId = id;
    }

    selectVoice(voice) {
        if (voice.trim().length <= 0) {
            return;
        }

        this.selectedVoice = voice;

        if (typeof pcuesExtensionInterface !== 'undefined' && pcuesExtensionInterface.setOption) {
            console.log('Applying voice: ' + voice);
            pcuesExtensionInterface.setOption('voiceType', voice);
        }

        // remove previous highlight
        let elems = document.querySelectorAll(".voice-type");
        [].forEach.call(elems, function(el) {
            el.classList.remove("selected-voice");
        });

        // highlight selected
        let img = this.settingsModal.querySelector('img[data-value="' + voice + '"]');
        if (img) {
            img.classList.add('selected-voice');
        }
    }


    /**
     * executes the automatic word replacement using the last used word
     * saved in the local storage
     */
    runAutoWordReplacement() {
        if (LocalStorageWrapper.get('replacementWord') && this.wordForReplacement) {
            let replacement = LocalStorageWrapper.get('replacementWord');
            this.doWordReplacement(replacement);
        }

        // inform everyone that this has been executed at least once
        this.hasRanAutoApplyLastReplacementWord = true;
    }

    /**
     * perform actual word replacement
     * @returns {boolean}
     */
    doWordReplacement(replacement) {
        let self = this;

        // sanitize
        replacement = replacement.trim();

        if (replacement.length > 0) {
            // replace on play audio blocks
            playAudioManager.replaceTextInAllBlocks(this.wordForReplacement, replacement);

            // replace on image audio
            let imageAudios = document.getElementById('main').querySelectorAll('.image-audio');
            for (let i = 0; i < imageAudios.length; i++) {
                let imageAudio = imageAudios[i];

                if (imageAudio.getAttribute('data-orig-text')) {
                    let regEx = new RegExp("\\b(" + this.wordForReplacement + ")+\\b", "gi");

                    // replace audio text
                    imageAudio.setAttribute('data-audio-text', imageAudio.getAttribute('data-orig-text').replace(regEx, replacement));

                    // replace audio tooltip
                    if (imageAudio.hasAttribute('data-title') && imageAudio.getAttribute('data-title').length > 0) {
                        imageAudio.setAttribute('data-title', imageAudio.getAttribute('data-title').replace(regEx, replacement));
                    }

                    if (imageAudio.hasAttribute('title') && imageAudio.getAttribute('title').length > 0) {
                        if (imageAudio.hasAttribute('data-title') && imageAudio.getAttribute('data-title').length > 0) {
                            imageAudio.setAttribute('title', imageAudio.getAttribute('data-title'));
                        }
                        else {
                            imageAudio.setAttribute('title', imageAudio.getAttribute('data-audio-text'));
                        }
                    }

                }
            }

            // replace on all the rest
            let prepareWordsInElement = function(container) {
                // for (let node of container.childNodes) {
                for (let i = 0; i < container.childNodes.length; i++) {
                    let node = container.childNodes[i];

                    if (node.classList && (node.classList.contains('playable-audio-wrapper') || node.classList.contains('pq-quiz-set')))
                    {
                        continue;
                    }

                    if (node.nodeType !== Node.TEXT_NODE) {
                        prepareWordsInElement(node);
                    }
                    else {
                        console.log('Found a text node! = ' + node.textContent);
                        // wrap word in span with
                        let span = document.createElement('span');

                        let regEx = new RegExp("\\b(" + self.wordForReplacement + ")+\\b", "gi");
                        span.innerHTML = node.nodeValue.replace(regEx, function(match, p1, offset) {
                            console.log('Found match for: ' + match);
                            let span = document.createElement('span');

                            span.setAttribute('data-search-word', match.toLowerCase()); // used for word replacements
                            span.textContent = match;

                            return span.outerHTML;
                        });

                        node.parentNode.replaceChild(span, node);
                    }
                }
            };

            if (!this.isDocPreparedForReplacement) {
                console.log('Preparing document');
                prepareWordsInElement(document.getElementById('main'));
                this.isDocPreparedForReplacement = true;
            }

            DemoModuleUtils.globalReplaceWord(this.wordForReplacement, replacement);

            // save word to history
            this.replacementWordHistory.add(replacement);

            // auto-close settings dialog
            this.settingsModal.style.display = "none";
        }

        return false;
    }

    processWordForReplacement() {
        let self = this;

        let modalContent = self.settingsModal.getElementsByClassName('modal-content')[0];

        if (this.wordForReplacement.trim().length > 0) {
            // @fixme: need to convert % to pixels
            let modalContentStyle = window.getComputedStyle(modalContent);
            // modalContent.style.marginTop = parseInt(modalContentStyle.getPropertyValue('margin-top')) - 60 + 'px';

            modalContent.style.marginTop = '5%';

            let replaceWord = document.getElementById('replace_word');

            // needed for pcued highlighting
            replaceWord.setAttribute('maxlength', (this.wordForReplacement.length + 4).toString());

            // initialize value of word replacement field
            if (LocalStorageWrapper.get('replacementWord')) {
                replaceWord.value = LocalStorageWrapper.get('replacementWord');
            }

            let targetWordHolder = document.getElementById('target_word_holder');
            targetWordHolder.textContent = this.wordForReplacement;

            let btnReplace = document.getElementById('btn_replace');


            btnReplace.addEventListener('click', function(e) {
                let replacement = replaceWord.value.trim();

                if (replacement.length > 0) {
                    LocalStorageWrapper.set('replacementWord', replacement);
                    self.doWordReplacement(replacement);
                }

                e.stopPropagation();
            });

            // if (this.autoApplyLastReplacementWord && !this.hasRanAutoApplyLastReplacementWord) {
            //     console.log('Running auto replace...');
            //     this.runAutoWordReplacement();
            // }
        }
        else {
            let container = document.getElementById('replace_word_component');
            container.parentNode.removeChild(container);

            let style = window.getComputedStyle(modalContent);

            modalContent.style.height = parseInt(style.getPropertyValue('height')) - 60 + 'px';

            let leftRegion = document.getElementById('left_region');
            let rightRegion = document.getElementById('right_region');
            let feedbackContent = document.getElementById('feedback_content');
            let changeVoice = document.getElementById('change_voice_type_component');

            // let embeddedForm = document.getElementById('embedded_jotform');
            let pqNotes = document.getElementById('author_notes');
            let contentNotes = document.getElementById('content_notes');

            let leftRegionStyle = window.getComputedStyle(leftRegion);
            let rightRegionStyle = window.getComputedStyle(rightRegion);
            let feedbackContentStyle = window.getComputedStyle(feedbackContent);
            let changeVoiceStyle = window.getComputedStyle(changeVoice);

            // let embeddedFormStyle = window.getComputedStyle(embeddedForm);
            let pqNotesStyle = window.getComputedStyle(pqNotes);
            let contentNotesStyle = window.getComputedStyle(contentNotes);

            leftRegion.style.height = parseInt(leftRegionStyle.getPropertyValue('height')) - 60 + 'px';
            rightRegion.style.height = parseInt(rightRegionStyle.getPropertyValue('height')) - 60 + 'px';
            feedbackContent.style.height = parseInt(feedbackContentStyle.getPropertyValue('height')) - 60 + 'px';
            changeVoice.style.marginTop = parseInt(changeVoiceStyle.getPropertyValue('margin-top')) - 10 + 'px';

            // embeddedForm.style.height = parseInt(embeddedFormStyle.getPropertyValue('height')) - 60 + 'px';
            pqNotes.style.height = parseInt(pqNotesStyle.getPropertyValue('height')) - 60 + 'px';
            contentNotes.style.height = parseInt(contentNotesStyle.getPropertyValue('height')) - 60 + 'px';
        }
    }

    processLibraryLink() {
        let libraryLinks = this.settingsModal.getElementsByClassName("library-link");
        for (let i = 0; i < libraryLinks.length; i++) {
            let link = libraryLinks[i];

            if (link.length <= 0) {
                link.setAttribute('href', "javascript:void(0)");
            }
            else if (this.libraryLink.toLowerCase().trim() === 'last') {
                if (document.referrer) {
                    let ref = document.referrer;
                    if (!ref.match(/^https?:\/\/([^\/]+\.)?mlc\.learningstewards\.org(\/|$)/i)) {
                        link.setAttribute('href', '/');
                    }
                    else {
                        link.setAttribute('href', "javascript:history.back()");
                    }
                }
                else {
                    link.setAttribute('href', "javascript:history.back()");
                }
            } else {
                link.setAttribute('href', this.libraryLink);
            }
        }
    }

    initSettingsDialog() {
        let self = this;

        // Get the modal
        this.settingsModal = document.getElementById('settings_dialog');
        console.log('Settings Modal:');
        console.log(this.settingsModal);

        document.getElementById('settings_button').addEventListener('click', function(event) {
            console.log('Displaying settings dialog.');
            self.settingsModal.style.display = "block";

            // interrupt playing audio blocks when settings dialog is shown
            if (playAudioManager.curPlayBlock) {
                playAudioManager.curPlayBlock.endPlay();
            }
        });

        let modalContent = document.querySelector('#settings_dialog .modal-content');

        let leftRegion = document.getElementById('left_region');
        let rightRegion = document.getElementById('right_region');

        if (DemoModuleUtils.getScreenWidth() > 700) {
            this.isWideLayout = true;
        } else {
            this.isWideLayout = false;

            // make advanced window flow to the bottom
            rightRegion.style.float = 'none';
            rightRegion.parentNode.insertBefore(rightRegion, leftRegion.nextSibling);
        }

        document.getElementById('show_advanced').addEventListener('click', function(event) {
            event.preventDefault();

            if (this.textContent === 'Advanced >>') {
                this.textContent = 'Less <<';
                this.setAttribute('title', 'Hides the advanced window');

                if (self.isWideLayout) {
                    rightRegion.style.float = 'right';
                    modalContent.style.width = '684px';
                    leftRegion.style.borderRight = '4px solid gray';
                }
                else {
                    leftRegion.style.borderBottom = '4px solid gray';

                    if (self.wordForReplacement.trim().length > 0) {
                        modalContent.style.height = '864px';
                    } else {
                        modalContent.style.height = '744px';
                    }
                }

                rightRegion.style.display = 'block';
            }
            else {
                this.textContent = 'Advanced >>';
                this.setAttribute('title', 'Opens and closes advanced features including: Feedback, Author\'s Notes and Citations, PQ Notes, and Social Media');

                resetFeedback();

                if (self.isWideLayout) {
                    modalContent.style.width = '340px';
                    leftRegion.style.borderRight = 'none';
                }
                else {
                    if (self.wordForReplacement.trim().length > 0) {
                        modalContent.style.height = '432px';
                    } else {
                        modalContent.style.height = '372px';
                    }

                    leftRegion.style.borderBottom = 'none';
                }

                rightRegion.style.display = 'none';
            }
        });

        /* START FEEDBACK */
        let ajaxUrl = '/wp-admin/admin-ajax.php?action=send_feedback';
        let sendButton = document.getElementById('feedback_send');
        let emailUsButton = document.getElementById('feedback_email_us');
        let isSending = false;
        let isCaptchaShown = false;

        /**
         * clears the feedback form
         */
        let resetFeedback = function() {
            console.log('Resetting feedback.');

            document.getElementById('feedback_confirmation').style.display = 'none';

            document.getElementById('feedback_content').value = '';
            document.getElementById('feedback_entry').style.display = 'block';

            let selectedFeedback = document.querySelector('.selected-feedback-icon');
            if (selectedFeedback) {
                selectedFeedback.classList.remove('selected-feedback-icon');
            }

            document.getElementById('feedback_content').style.visibility = 'visible';
            document.getElementById('captcha_container').style.visibility = 'hidden';

            isCaptchaShown = false;
            isSending = false;
            document.getElementById('feedback_send').style.opacity = '1';

            // document.getElementById('embedded_jotform_iframe').src = '';
            // document.getElementById('embedded_jotform_iframe').src = 'https://form.jotform.com/80032550784958??pageName=mlctest';
        };

        emailUsButton.addEventListener('click', function(event) {
            let selectedReaction = document.querySelector('.selected-feedback-icon');
            let href = 'feedback@learningstewards.org';

            let subject = 'MLC Feedback[' + self.pageName + ']';
            if (selectedReaction) {
                subject += '[' + selectedReaction.getAttribute('title') + ']';
            }

            let body = 'URL: ' + location.href;
            body += "\n--------------------------------------------------------\nPlease enter your message here:\n\n";
            body = encodeURIComponent(body);

            location.href = 'mailto: ' + href + '?subject=' + subject + '&body=' + body;
            window.open('mailto: ' + href + '?subject=' + subject + '&body=' + body, '_blank');
        });

        sendButton.addEventListener('click', function(event) {
            if (isSending) {
                return;
            }

            let selectedReaction = document.querySelector('.selected-feedback-icon');
            if (!selectedReaction) {
                document.getElementById('feedback_no_reaction_error').style.display = 'block';
                setTimeout(function() {
                    document.getElementById('feedback_no_reaction_error').style.display = 'none';
                }, 5000);
                return;
            }

            let feedbackContainer = document.getElementById('feedback_content');
            feedbackContainer.style.visibility = 'hidden';

            let isCaptchaValid = false;
            let captchaResponse = document.getElementById('g-recaptcha-response');
            if (captchaResponse && captchaResponse.value) {
                isCaptchaValid = true;
            }

            if (!isCaptchaValid && !isCaptchaShown) {
                let captchaContainer = document.getElementById('captcha_container');
                captchaContainer.style.visibility = 'visible';

                feedbackContainer.style.visibility = 'hidden';

                isCaptchaShown = true;
                return;
            }

            console.log('Sending feedback...');
            isSending = true;
            document.getElementById('feedback_send').style.opacity = '0.4';

            let feedback = feedbackContainer.value;

            let reaction = selectedReaction.getAttribute('title');
            let url = location.href;

            // gather comment and emoticon, then send via AJAX
            let request = new XMLHttpRequest();

            request.onreadystatechange = function() {
                if (this.readyState === XMLHttpRequest.DONE) {
                    if (this.status === 200 && this.responseText === '1') {
                        document.getElementById('feedback_entry').style.display = 'none';
                        document.getElementById('feedback_confirmation').style.display = 'block';

                        setTimeout(function() {
                            document.getElementById('feedback_confirmation').style.display = 'none';
                            document.getElementById('feedback_entry').style.display = 'block';

                            resetFeedback();
                            document.getElementById('feedback_content').focus();
                        }, 3000);
                    }

                    console.log('Feedback sent.');
                }
            };

            request.open("POST", ajaxUrl, true);
            request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            request.send('feedback=' + encodeURIComponent(feedback)
                + '&reaction=' + encodeURIComponent(reaction)
                + '&url=' + encodeURIComponent(url)
                + '&g-recaptcha-response=' + captchaResponse.value
                + '&pageId=' + encodeURIComponent(self.pageId));
        });

        // emoticon selection
        let feedbackIcons = document.getElementsByClassName('feedback-icon');
        for (let i = 0; i < feedbackIcons.length; i++) {
            feedbackIcons[i].addEventListener('click', function(event) {
                for (let j = 0; j < feedbackIcons.length; j++) {
                    feedbackIcons[j].classList.remove('selected-feedback-icon');
                }

                this.classList.add('selected-feedback-icon');
            });
        }
        /* END FEEDBACK */

        // @todo: make this a public method of SettingsManager
        let closeSettingsDialog = function() {
            self.settingsModal.style.display = "none";

            document.getElementById('show_advanced').textContent = 'Advanced >>';
            document.getElementById('show_advanced').setAttribute('title', 'Opens and closes advanced features including: Feedback, Author\'s Notes and Citations, PQ Notes, and Social Media');

            resetFeedback();

            if (self.isWideLayout) {
                document.querySelector('#settings_dialog .modal-content').style.width = '340px';
                document.getElementById('left_region').style.borderRight = 'none';
            }
            else {
                if (self.wordForReplacement.trim().length > 0) {
                    modalContent.style.height = '432px';
                } else {
                    modalContent.style.height = '372px';
                }

                document.getElementById('left_region').style.borderBottom = 'none';
            }

            document.getElementById('right_region').style.display = 'none';
        };

        let closeLinks = this.settingsModal.getElementsByClassName("close");
        for (let i = 0; i < closeLinks.length; i++) {
            closeLinks[i].onclick = closeSettingsDialog;
        }

        // When the user clicks anywhere outside of the modal, close it
        // this.settingsModal.addEventListener('click', function() {
        //     closeSettingsDialog();
        // });
        window.onclick = function(event) {
            if (event.target === self.settingsModal) {
                closeSettingsDialog();
            }
        };

        document.onkeydown = function(event) {
            let isEscape = false;

            if ("key" in event) {
                isEscape = (event.key === "Escape" || event.key === "Esc");
            } else {
                isEscape = (event.keyCode === 27);
            }

            if (isEscape) {
                if (self.settingsModal.style.display === 'block') {
                    closeSettingsDialog();
                }
            }
        };

        // change voice speed
        let voiceButtons = document.getElementsByClassName('change-voice');
        for (let i = 0; i < voiceButtons.length; i++) {
            let button = voiceButtons[i];
            let self = this;

            button.addEventListener('click', function(event) {
                let params = this.value.split(',');
                self.rateSpeed = params[0];
                self.interWordDelay = params[1];
                // [self.rateSpeed, self.interWordDelay] = this.value.split(',');

                LocalStorageWrapper.set('speechRate', self.rateSpeed);
                LocalStorageWrapper.set('interwordDelayMS', self.interWordDelay);

                self.rateSpeed = parseFloat(self.rateSpeed);
                self.interWordDelay = parseInt(self.interWordDelay);

                self.useGlobalSpeed = true;
                LocalStorageWrapper.set('useGlobalSpeed', 'true');
            });
        }

        // change voice speed
        let changeVoices = self.settingsModal.getElementsByClassName('change-voice');
        let lookupVal = LocalStorageWrapper.get('speechRate') + ',' + LocalStorageWrapper.get('interwordDelayMS');

        for (let i = 0; i < changeVoices.length; i++) {
            let changeVoice = changeVoices[i];
            if (changeVoice.value === lookupVal) {
                changeVoice.checked = true;
            }
        }

        /* Hover switch */
        let hoverSwitch = document.getElementById('hover_switch');

        // initialize hover state
        if (LocalStorageWrapper.get('hoverState') !== null) {
            self.hoverState = LocalStorageWrapper.get('hoverState') === 'true';
        } else {
            self.hoverState = self.DEFAULT_HOVER_STATE;
            LocalStorageWrapper.set('hoverState', self.hoverState);
        }

        hoverSwitch.checked = self.hoverState;

        hoverSwitch.addEventListener('change', function(event) {
            self.hoverState = hoverSwitch.checked;
            LocalStorageWrapper.set('hoverState', self.hoverState);

            // make sure image audio buttons have
            let hoverImages = document.querySelectorAll('img[data-play-on-hover="1"]');
            for (let i = 0; i < hoverImages.length; i++) {
                let image = hoverImages[i];

                if (!self.hoverState) {
                    if (image.hasAttribute('data-title')) {
                        image.setAttribute('title', image.getAttribute('data-title'));
                    } else {
                        image.setAttribute('title', image.getAttribute('data-audio-text'));
                    }
                } else {
                    image.setAttribute('title', '');
                }
            }
        });
        /* End hover switch */

        this.initStewardOptions();

        let feedback = document.getElementById('feedback_component');
        let pqNotes = document.getElementById('author_notes');
        let contentNotes = document.getElementById('content_notes');
        let stewardOptions = document.getElementById('steward_options');

        // Feedback button switch
        let feedbackSwitch = document.getElementById('feedback_button');
        feedbackSwitch.onclick = function(event) {
            resetFeedback();
            self.isFeedbackShown = !self.isFeedbackShown;

            if (self.isFeedbackShown) {
                feedback.style.display = 'block';

                pqNotes.style.display = 'none';
                self.isNotesShown = false;

                contentNotes.style.display = 'none';
                self.isContentNotesShown = false;

                stewardOptions.style.display = 'none';
                self.isStewardOptionsShown = false;
            } else {
                if (self.isNotesShown || self.isContentNotesShown || self.isStewardOptionsShown) {
                    feedback.style.display = 'none';
                } else {
                    self.isFeedbackShown = true;
                }
            }
        };

        // PQ Notes Switch
        let pqNotesSwitches = document.getElementsByClassName('pq-notes-switch');
        for (let i = 0; i < pqNotesSwitches.length; i++) {
            let pqNotesSwitch = pqNotesSwitches[i];
            pqNotesSwitch.addEventListener('click', function(event) {
                if (this.hasAttribute('data-disabled') && this.getAttribute('data-disabled') === 'true') {
                    return;
                }

                self.isNotesShown = !self.isNotesShown;

                if (self.isNotesShown) {
                    pqNotes.style.display = 'block';

                    feedback.style.display = 'none';
                    self.isFeedbackShown = false;

                    contentNotes.style.display = 'none';
                    self.isContentNotesShown = false;

                    stewardOptions.style.display = 'none';
                    self.isStewardOptionsShown = false;
                } else {
                    pqNotes.style.display = 'none';

                    if (!self.isContentNotesShown && !self.isFeedbackShown && !self.isStewardOptionsShown) {
                        feedback.style.display = 'block';
                        self.isFeedbackShown = true;
                    }
                }
            });
        }

        // Content Notes Switch
        let contentNotesSwitches = document.getElementsByClassName('content-notes-switch');
        for (let i = 0; i < contentNotesSwitches.length; i++) {
            let contentNotesSwitch = contentNotesSwitches[i];
            contentNotesSwitch.addEventListener('click', function(event) {
                if (this.hasAttribute('data-disabled') && this.getAttribute('data-disabled') === 'true') {
                    return;
                }

                self.isContentNotesShown = !self.isContentNotesShown;

                if (self.isContentNotesShown) {
                    contentNotes.style.display = 'block';

                    feedback.style.display = 'none';
                    self.isFeedbackShown = false;

                    pqNotes.style.display = 'none';
                    self.isNotesShown = false;

                    stewardOptions.style.display = 'none';
                    self.isStewardOptionsShown = false;
                } else {
                    contentNotes.style.display = 'none';

                    if (!self.isNotesShown && !self.isFeedbackShown && !self.isStewardOptionsShown) {
                        feedback.style.display = 'block';
                        self.isFeedbackShown = true;
                    }
                }
            });
        }

        let stewardOptionsSwitch = document.getElementById('steward_options_switch');
        stewardOptionsSwitch.addEventListener('click', function(event) {
            self.isStewardOptionsShown = !self.isStewardOptionsShown;

            if (self.isStewardOptionsShown) {
                stewardOptions.style.display = 'block';

                pqNotes.style.display = 'none';
                self.isNotesShown = false;

                contentNotes.style.display = 'none';
                self.isContentNotesShown = false;

                feedback.style.display = 'none';
                self.isFeedbackShown = false;
                resetFeedback();
            } else {
                if (self.isNotesShown || self.isContentNotesShown || self.isFeedbackShown) {
                    stewardOptions.style.display = 'none';
                } else {
                    self.isStewardOptionsShown = true;
                }
            }
        });

        this.initVoiceType();
        this.initVoiceSpeed();

        console.log('Settings dialog processed.');
    }

    initVoiceSpeed() {
        let self = this;

        // initialize global speed
        if (LocalStorageWrapper.get('speechRate') !== null && LocalStorageWrapper.get('interwordDelayMS') !== null) {
            self.rateSpeed = LocalStorageWrapper.get('speechRate');
            self.interWordDelay = LocalStorageWrapper.get('interwordDelayMS');

            // update UI
            let changeVoices = document.getElementsByTagName('change-voice');
            for (let i = 0; i < changeVoices.length; i++) {
                let changeVoice = changeVoices[i];

                if (changeVoice.value === (self.rateSpeed + ',' + self.interWordDelay)) {
                    console.log('Found!');
                    changeVoice.checked = true;
                    break;
                }
            }

            self.rateSpeed = parseFloat(self.rateSpeed);
            self.interWordDelay = parseInt(self.interWordDelay);

            self.useGlobalSpeed = true;
            LocalStorageWrapper.set('useGlobalSpeed', 'true');
        }
    }

    initVoiceType() {
        let self = this;
        let voiceType = null;

        let bindVoiceTypesClick = function() {
            let voiceTypes = document.getElementsByClassName('voice-type');
            for (let i = 0; i < voiceTypes.length; i++) {
                voiceTypes[i].addEventListener('click', function(event) {
                    self.selectVoice(this.getAttribute('data-value'));
                    LocalStorageWrapper.set('selectedVoice', this.getAttribute('data-value'));
                });
            }
        };

        if (LocalStorageWrapper.get('selectedVoice') !== null) {
            voiceType = LocalStorageWrapper.get('selectedVoice');
        } else {
            voiceType = this.selectedVoice;
        }

        if (typeof pcuesAudioApi !== 'undefined') {
            this.selectVoice(voiceType);
            bindVoiceTypesClick();
        }
        else {
            document.body.addEventListener('onPcuesExtensionLoaded', function(event) {
                if (LocalStorageWrapper.get('selectedVoice') !== null) {
                    self.selectVoice(LocalStorageWrapper.get('selectedVoice'));
                } else if (self.pageSelectedVoice !== null) {
                    self.selectVoice(self.pageSelectedVoice);
                } else {
                    self.selectVoice(self.DEFAULT_SELECTED_VOICE);
                }

                bindVoiceTypesClick();
            });
        }
    }

    /**
     * initializes the value of the WordScope button switch in the PQ popup
     * Sequence of setting determination: Local Storage, Page Setting, Global Setting
     */
    initStewardOptions() {
        let self = this;
        let wsButtonSwitch = document.getElementById('ws_button_switch');

        // ################################### START MY STUFF ########################################
        const myStuffLockSwitch = document.getElementById('my_stuff_lock_source');
        const myStuffShowThumbnailsSwitch = document.getElementById('my_stuff_show_thumbnails');
        const myStuffLockSettingName = 'isMyStuffSourceLocked';
        const myStuffShowThumbnailsSettingName = 'isMyStuffThumbnailsShown';

        document.getElementById('my_stuff_source_button').addEventListener('click', function(event) {
            let existing = '';
            if (LocalStorageWrapper.get('googleDriveLink')) {
                existing = LocalStorageWrapper.get('googleDriveLink');
            }

            let source = prompt('Google Drive Link', existing);
            if (source !== null) {
                if (source.trim() !== '') {
                    LocalStorageWrapper.set('googleDriveLink', source);
                    alert('MyStuff Source Gdrive has been updated.');
                }
                else {
                    LocalStorageWrapper.remove('googleDriveLink');
                    alert('MyStuff Source Gdrive has been cleared.');
                }
            }
        });

        // initialize
        myStuffLockSwitch.checked = ['true', null].indexOf(LocalStorageWrapper.get(myStuffLockSettingName)) !== -1;

        myStuffLockSwitch.addEventListener('change', function(event) {
            if (LocalStorageWrapper.get(myStuffLockSettingName) === 'false') {
                LocalStorageWrapper.set(myStuffLockSettingName, 'true');
            } else {
                LocalStorageWrapper.set(myStuffLockSettingName, 'false');
            }
        });

        myStuffShowThumbnailsSwitch.checked = LocalStorageWrapper.get(myStuffShowThumbnailsSettingName) === 'true';

        myStuffShowThumbnailsSwitch.addEventListener('change', function(event) {
            LocalStorageWrapper.set(myStuffShowThumbnailsSettingName, this.checked);
            let thumbnails = document.getElementsByClassName('mystuff-thumb-file');
            let switchIsOn = this.checked;

            Array.from(thumbnails).forEach(function (thumbnail) {
                if (switchIsOn) {
                    thumbnail.style.display = 'inline-block';
                } else {
                    thumbnail.style.display = 'none';
                }
            });
        });
        // #################################### END MY STUFF #########################################

        // pre populate
        console.log('WS BUTTON: Initiating WS button setting');
        if (LocalStorageWrapper.get('wsButtonState') !== null) {
            console.log('WS BUTTON: setting found in localstorage');
            if (LocalStorageWrapper.get('wsButtonState') === 'true') {
                console.log('WS BUTTON: Save value is true');
                this.wsButtonState = true;
                wsButtonSwitch.checked = true;
            } else {
                console.log('WS BUTTON: Save value is false');
                this.wsButtonState = false;
                wsButtonSwitch.checked = false;
            }
        }
        else {
            console.log('WS BUTTON: Not in localstorage. Value is: ' + this.wsButtonState);
            wsButtonSwitch.checked = this.wsButtonState;
        }

        document.body.addEventListener('onPcuesExtensionLoaded', function() {

            if (self.wsPageButtonState) {
                console.log('WS BUTTON: Page setting override to true.');
                pcuesExtensionInterface.setOption('popupLinkType', 'reference');
            } else {
                console.log('WS BUTTON: Setting initial value to: ' + self.wsButtonState);
                pcuesExtensionInterface.setOption('popupLinkType', self.wsButtonState ? 'reference' : 'none');
            }

            wsButtonSwitch.addEventListener('change', function(event) {
                self.wsButtonState = wsButtonSwitch.checked;
                LocalStorageWrapper.set('wsButtonState', self.wsButtonState);

                // make sure image audio buttons have
                pcuesExtensionInterface.setOption('popupLinkType', self.wsButtonState ? 'reference' : 'none');
            });
        });

        // Start Auto Replace setting
        let autoApplyReplacementWordSwitch = document.getElementById('replacement_word_auto_apply');
        if (LocalStorageWrapper.get('autoApplyLastReplacementWordState') !== null) {
            if (LocalStorageWrapper.get('autoApplyLastReplacementWordState') === 'true') {
                this.autoApplyLastReplacementWord = true;
                autoApplyReplacementWordSwitch.checked = true;
            }
            else {
                this.autoApplyLastReplacementWord = false;
                autoApplyReplacementWordSwitch.checked = false;
            }
        }

        autoApplyReplacementWordSwitch.addEventListener('change', function(event) {
            self.autoApplyLastReplacementWord = autoApplyReplacementWordSwitch.checked;
            LocalStorageWrapper.set('autoApplyLastReplacementWordState', self.autoApplyLastReplacementWord);

            if (self.autoApplyLastReplacementWord && !self.hasRanAutoApplyLastReplacementWord) {
                self.runAutoWordReplacement();
            }
        });
        // End auto replace setting

        let bindResetSettingsClick = function() {
            document.getElementById('reset_settings_button').addEventListener('click', function (event) {
                let response = confirm('Are you sure you want to reset all the settings?');
                if (!response) {
                    return;
                }

                LocalStorageWrapper.clear();

                // WS button switch
                if (self.wsPageButtonState !== null) {
                    self.wsButtonState = self.wsPageButtonState;
                } else {
                    self.wsButtonState = self.wsGlobalButtonState;
                }

                wsButtonSwitch.checked = self.wsButtonState;

                if (typeof pcuesExtensionInterface !== 'undefined') {
                    pcuesExtensionInterface.setOption('popupLinkType', self.wsButtonState ? 'reference' : 'none');
                }
                // End WS button switch

                // auto replace button switch
                self.autoApplyLastReplacementWord = self.DEFAULT_AUTO_APPLY_LAST_REPLACEMENT_WORD;
                autoApplyReplacementWordSwitch.checked = self.DEFAULT_AUTO_APPLY_LAST_REPLACEMENT_WORD;
                // End auto replace button switch

                /* Hover switch */
                self.hoverState = self.DEFAULT_HOVER_STATE;
                document.getElementById('hover_switch').checked = self.hoverState;
                LocalStorageWrapper.set('hoverState', self.hoverState);

                let hoverImageAudioItems = document.querySelectorAll('img[data-play-on-hover="1"]');
                for (let i = 0; i < hoverImageAudioItems.length; i++) {
                    hoverImageAudioItems[i].setAttribute('title', '');
                }
                /* End hover switch */

                // Voice type
                if (self.pageSelectedVoice !== null) {
                    self.selectVoice(self.pageSelectedVoice);
                } else {
                    self.selectVoice(self.DEFAULT_SELECTED_VOICE);
                }
                // End voice type

                // Voice speed
                self.rateSpeed = null;
                self.interWordDelay = null;
                self.useGlobalSpeed = false;
                LocalStorageWrapper.set('useGlobalSpeed', 'false');

                document.getElementById('normal_voice_speed').checked = true;
                // End voice speed

                let resetConfirmation = document.getElementById('reset_settings_confirm_msg');

                resetConfirmation.style.display = 'block';
                setTimeout(function() {
                    resetConfirmation.style.display = 'none';
                }, 3000);
            });
        };

        /* Reset Settings */
        if (typeof pcuesAudioApi !== 'undefined') {
            bindResetSettingsClick();
        } else {
            document.body.addEventListener('onPcuesExtensionLoaded', function() {
                bindResetSettingsClick();
            });
        }
    }

    initialize() {
        this.initSettingsDialog();
    }

    /**
     * only used for setting parameter but not actually applying it
     * @param voice
     */
    setSelectedVoice(voice) {
        if (voice.length <= 0) {
            return;
        }

        this.pageSelectedVoice = voice;

        if (!LocalStorageWrapper.get('selectedVoice')) {
            this.selectVoice(this.pageSelectedVoice);
        }
    }

    setAvailableVoices() {

    }

    /**
     * pass PQ notes to the Help and Settings dialog
     * @param notes
     */
    setNotes(notes) {
        this.notes = decodeURIComponent(notes);

        let pqNotesSwitch = document.querySelector('.pq-notes-switch');

        if (notes.length <= 0) {
            pqNotesSwitch.style.opacity = '0.4';
            pqNotesSwitch.setAttribute('data-disabled', 'true');
        } else {
            document.getElementById('author_notes').innerHTML = DemoModuleUtils.linkify(this.notes);
        }
    }

    setContentNotes(notes) {
        this.contentNotes = decodeURIComponent(notes).replace(/\n/g, "<br />");

        let contentNotesSwitch = document.querySelector('.content-notes-switch');

        if (notes.length <= 0) {
            contentNotesSwitch.style.opacity = '0.4';
            contentNotesSwitch.setAttribute('data-disabled', 'true');
        } else {
            document.getElementById('content_notes').innerHTML = DemoModuleUtils.linkify(this.contentNotes);
        }
    }

    setWordForReplacement(word) {
        this.wordForReplacement = word;
        this.processWordForReplacement();

        // auto-apply
        // this.doWordReplacement(word);
    }
}